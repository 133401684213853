// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
// const localHost = `https://sipdemo.ekaushal.com:800`;
// const uahost = `http://${window.ocation.hostname}`;
// const testHostName = `http://13.126.105.23`;
const testHostName = `https://sipdemo.ekaushal.com`;
const uat = `http://13.232.166.60`;

const dockerHost = `https://sipdemo.ekaushal.com`;
const localHost = `http://${window.location.hostname}`;
const currentHost = `https://sipdemo.ekaushal.com`;
const functionalities = {
	payments: {
		suspendPayment: false,
		TPPayment: true,
                SchemePayment: false, 
		SelectSchemePayment: false,
                Pmkvy40Payment: true,
                TCPayment: true,
	},
	payment: false,
	console: true,
	formTest: false,
	uploadFromServer: false,
	showUserNameAndPassword: true,
        disableAadharChecksumVerification: false,
        form : {
        disallowNameConflict: true,
    }
};
const clientOrigin = window.location.origin;
export const environment = Object.freeze({
	production: false,
	sidServiceUrl: currentHost ,
	userServiceUrl: currentHost ,
	geographyServiceUrl: currentHost ,
	ToTServiceURL: currentHost ,
	pdfGenerate: currentHost ,
	batchServiceUrl: currentHost ,
	nsdcCommonServiceUrl: currentHost ,
	sqaServiceUrl: currentHost ,
	schemeServiceURL: currentHost ,
	trainingPartnerServiceURL: currentHost ,
	smartServiceURL: currentHost ,
	documentServiceUrl: currentHost ,
	// documentServiceUrl: currentHost ,
	authServiceURL: currentHost ,
	paymentService: currentHost ,
	pmkvycmServiceUrl: currentHost,
        rozgarMelaUrl: currentHost ,
	aadharServiceURL : currentHost ,
        excelService: currentHost ,
        utilityService : currentHost ,
        userManagmentServiceUrl : currentHost ,
        learningServiceUrl : currentHost ,
	loginService : currentHost ,
	sipReportingServiceUrl : currentHost ,
	userManagementServiceUrl : currentHost ,
	'google-reCaptcha-data-site-key':
		'6Le3uyAUAAAAAFIk054LtGL8o0LI3uiUz3cphqVw',
	amazonS3: 'http',
	csrf_url: `https://sipdemo.ekaushal.com/api/user/v1` ,
	clientHost: clientOrigin,
	functionalities
});



